import * as v from "valibot";

export const incorporationNewCompanyFormSchema = v.object({
  country_id: v.object({
    label: v.pipe(v.string(), v.minLength(1)),
    value: v.pipe(v.number(), v.minValue(1)),
  }),
});

export type IncorporationNewCompanyForm = v.InferOutput<typeof incorporationNewCompanyFormSchema>;

export const incorporationFormDetailsSchema = v.object({
  country_id: v.object({
    label: v.pipe(v.string(), v.minLength(1)),
    value: v.pipe(v.number(), v.minValue(1)),
  }),
  trial_name_1: v.pipe(v.string(), v.minLength(1)),
  trial_name_2: v.pipe(v.string(), v.minLength(1)),
  trial_name_3: v.pipe(v.string(), v.minLength(1)),
  social_capital: v.pipe(v.number(), v.minValue(1)),
  social_object: v.pipe(v.string(), v.minLength(1)),
});

export type IncorporationFormDetails = v.InferOutput<typeof incorporationFormDetailsSchema>;
