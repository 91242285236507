import queryClient from "@/config/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

export default function ReactQueryProvider(props: ReactQueryProviderProps) {
  const { children } = props;

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
