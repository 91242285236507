import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { toast } from "react-toastify";

const AUTO_CLOSE_TIMER = 3000;

export function toastSuccess(message: string) {
  return toast.success(message, {
    icon: <IconCircleCheck className="text-success" />,
    autoClose: AUTO_CLOSE_TIMER,
  });
}

export function toastError(message: string) {
  return toast.success(message, {
    icon: <IconCircleX className="text-danger" />,
    autoClose: AUTO_CLOSE_TIMER,
  });
}
