import { type GetGroupResponse, getGroup } from "@/services/group/groupQueries";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/group/$groupIdentifier/edit")({
  loader: async ({ params }) => {
    try {
      const { groupIdentifier } = params;
      const reponse = await getGroup(groupIdentifier);
      if (reponse.ok) {
        return await reponse.json<GetGroupResponse>();
      }
    } catch (_error) {
      throw redirect({ to: ".." });
    }
  },
});
