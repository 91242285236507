import { type GetGroupCompanyResponse, getGroupCompany } from "@/services/companies/companiesQueries";
import store from "@/stores/store";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/group/$groupIdentifier/incorporation/$companyIdentifier")({
  beforeLoad: async ({ params, location }) => {
    const { companyIdentifier, groupIdentifier } = params;
    const incorporationData = store.getState().incorporationData;
    const ignorePaths = ["/step/", "/shareholders/", "/managers/"];

    if (
      incorporationData &&
      incorporationData.identifier === companyIdentifier &&
      ignorePaths.some((path) => location.pathname.includes(path))
    ) {
      return;
    }

    if (companyIdentifier === "create-new") {
      if (location.pathname.includes("/step/")) return;
      throw redirect({
        to: "/group/$groupIdentifier/incorporation/$companyIdentifier/step/1",
        params: { groupIdentifier, companyIdentifier: "create-new" },
      });
    }

    const response = await getGroupCompany({ groupIdentifier, companyIdentifier });
    if (response.ok) {
      const data = await response.json<GetGroupCompanyResponse>();
      const incorporationStep = data.incorporation_last_completed_step + 1 || 1;
      const newCompanyIndentifier = data.identifier;

      if (incorporationStep && newCompanyIndentifier) {
        store.getState().setIncorporationData(data);
        throw redirect({
          to: `/group/$groupIdentifier/incorporation/$companyIdentifier/step/${incorporationStep}`,
          params: {
            groupIdentifier,
            companyIdentifier: newCompanyIndentifier,
          },
        });
      }
    }

    store.getState().setIncorporationData(undefined);
    throw redirect({
      to: "/group/$groupIdentifier/companies",
      params: { groupIdentifier },
    });
  },
});
