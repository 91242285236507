import { type GetGroupAdminResponse, getGroupAdmin } from "@/services/admin/groups/adminGroupsQueries";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/admin/groups/$groupIdentifier")({
  loader: async ({ params }) => {
    try {
      const { groupIdentifier } = params;
      const response = await getGroupAdmin(groupIdentifier);

      if (response.ok) {
        return await response.json<GetGroupAdminResponse>();
      }
    } catch (_error) {
      /* empty */
    }
    throw redirect({ to: ".." });
  },
});
