import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type * as v from "valibot";
import type { CountryOption, GroupsOption } from "../../options/optionsQueries";
import type { PaginationParamsSchema } from "../../servicesSchemas";

export interface CustomerListObj {
  id: number;
  group_name: string | null;
  key_contact_full_name: string | null;
  name: string | null;
  status: string | null;
}

type CustomersList = CustomerListObj[];
export interface GetCustomersResponse extends Pagination {
  items: CustomersList;
}

export type GetCustomersParams = v.InferOutput<typeof PaginationParamsSchema>;

export function getCustomers(params: GetCustomersParams) {
  const { page, sort } = params;
  const searchParams: GetCustomersParams = {
    page,
  };
  if (sort) searchParams.sort = sort;
  return httpClient.get("admin/customers", { searchParams });
}

export function useGetCustomers(params: GetCustomersParams) {
  const { page, sort } = params;
  return useQuery({
    queryKey: ["admin", "customers", page, sort],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await getCustomers(params);
      return await response.json<GetCustomersResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
  });
}

export interface GetCustomer {
  id: number;
  address: string;
  name: string;
  taxpayer_id: string;
  country: CountryOption;
  ss: string;
  company_number: string;
  group: GroupsOption;
  billing_contact_email: string;
  billing_contact_full_name: string;
  billing_contact_telephone: string;
  key_contact_email: string;
  key_contact_full_name: string;
  key_contact_telephone: string;
  signer_email: string;
  signer_full_name: string;
  signer_telephone: string;
  special_about_billing?: string;
  signer_title: string;
  status: string;
}

export type GetCustomerResponse = GetCustomer;

export function getCustomer(id: string) {
  return httpClient.get(`admin/customers/${id}`);
}
