import { checkIsGroupAdmin } from "@/utils/auth";
import { getUser } from "@/utils/user";
import { createFileRoute, notFound } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/group/$groupIdentifier")({
  beforeLoad: async ({ params }) => {
    const { groupIdentifier } = params;
    const isGroupAdmin = await checkIsGroupAdmin();
    if (!isGroupAdmin) {
      throw notFound();
    }

    const user = getUser();
    if (groupIdentifier !== user?.group?.identifier) {
      throw notFound();
    }
  },
});
