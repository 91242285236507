import type { UserObj } from "@/services/auth/mutations";
import authStore from "@/stores/authStore";
import dayjs from "dayjs";

export function encryptJWT(payload: Record<string, unknown>) {
  return btoa(JSON.stringify(payload));
}

export function decryptJWT(token: string) {
  const clearUser = authStore.getState().clearUser;

  try {
    const result = JSON.parse(atob(token)) as Record<string, unknown>;

    if (result?.expired_at && dayjs().isAfter(dayjs(result.expired_at as string))) {
      clearUser();
      window.location.href = "/";
    }

    return result;
  } catch (_error) {
    clearUser();
    window.location.href = "/";
  }
}

export function getUser() {
  const user = authStore.getState().user;
  if (!user) return undefined;

  return decryptJWT(user) as unknown as UserObj;
}
