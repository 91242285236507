import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { ManagerMember } from "../incorporation/managers/incorporationManagersQueries";
import type { ShareholderMember } from "../incorporation/shareholders/incoporationShareholdersQueries";

interface GroupCompany {
  id: number;
  name: string;
  identifier: string;
}

export interface Company {
  id: number;
  name: string;
  status: string;
  identifier: string;
  logo_path: string;
  country: string;
  mode: string;
  group: GroupCompany;
}

type GetGroupCompanies = Company[];
export interface GetGroupCompaniesResponse extends Pagination {
  items: GetGroupCompanies;
}

export interface GetGroupCompaniesParams {
  groupIdentifier: string;
}

export function getGroupCompanies(params: GetGroupCompaniesParams) {
  const { groupIdentifier } = params;
  return httpClient.get(`group/${groupIdentifier}/companies`);
}

export function useGetGroupCompanies(params: GetGroupCompaniesParams) {
  const { groupIdentifier } = params;
  return useQuery({
    queryKey: ["group", groupIdentifier, "companies"],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await getGroupCompanies(params);
      return await response.json<GetGroupCompaniesResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
  });
}

export interface GetGroupCompanyParams {
  groupIdentifier: string;
  companyIdentifier: string;
}

interface GroupCompanyGroup {
  id?: number;
  name?: string;
  identifier: string;
}

interface GroupCompanyCustomer {
  id?: number;
  name?: string;
}

export interface GetGroupCompanyResponse {
  id: number;
  address?: string;
  bank_account_created?: string;
  city?: string;
  country: string;
  customer: GroupCompanyCustomer;
  employees_requirements?: string;
  group: GroupCompanyGroup;
  identifier: string;
  incorporation_last_completed_step: number;
  logo_path: string;
  mode: string;
  name: string;
  office_requirements?: string;
  postal_code?: string;
  ramp_up_finished?: string;
  region?: string;
  social_capital: number;
  social_object: string;
  status: string;
  trial_name_1: string;
  trial_name_2: string;
  trial_name_3: string;
  virtual_office?: string;
  shareholders?: ShareholderMember[];
  managers?: ManagerMember[];
}

export function getGroupCompany(params: GetGroupCompanyParams) {
  const { groupIdentifier, companyIdentifier } = params;
  return httpClient.get(`group/${groupIdentifier}/companies/${companyIdentifier}`);
}
