import queryClient from "@/config/queryClient";
import { getIncorporationShareholderMemberOptions } from "@/services/incorporation/shareholders/incoporationShareholdersQueries";
import {
  getCountriesOptions,
  getMaritalRegimesOptions,
  getMaritalStatusesOptions,
  getShareholderTypesOptions,
} from "@/services/options/optionsQueries";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/group/$groupIdentifier/incorporation/$companyIdentifier/shareholders/$shareholderId",
)({
  loader: ({ params }) => {
    const { groupIdentifier, companyIdentifier, shareholderId } = params;
    const shareholderTypesPromise = queryClient.ensureQueryData(getShareholderTypesOptions);
    const maritalRegimePromise = queryClient.ensureQueryData(getMaritalRegimesOptions);
    const maritalStatusPromise = queryClient.ensureQueryData(getMaritalStatusesOptions);
    const countriesPromise = queryClient.ensureQueryData(getCountriesOptions);
    const getShareholderPromise = queryClient.ensureQueryData(
      getIncorporationShareholderMemberOptions({ groupIdentifier, companyIdentifier, shareholderId }),
    );

    return Promise.all([
      shareholderTypesPromise,
      maritalRegimePromise,
      maritalStatusPromise,
      countriesPromise,
      getShareholderPromise,
    ]);
  },
});
