import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import type { User } from "../auth/mutations";
import type { Company } from "../companies/companiesQueries";
import type { CountryOption } from "../options/optionsQueries";
import type { Dependant } from "./onboardingMutations";

export interface OnboardingObj {
  added_to_labour_insurance_policy?: boolean;
  added_to_phi?: boolean;
  added_to_segumet?: boolean;
  birthdate?: string;
  city?: string;
  company: Company;
  company_email_alias?: string;
  contract_type?: string;
  country?: CountryOption;
  coverflex_added?: boolean;
  created_at: string;
  cv_file?: string;
  declaration_to_waive_phi?: string;
  deduction_declaration_signed?: boolean;
  default_trial_period: boolean;
  dependants?: Dependant[];
  education?: SelectOption;
  email: string;
  email_add_ss?: boolean;
  email_request_add_labour_insurance?: boolean;
  email_request_add_phi?: boolean;
  email_request_segumet?: boolean;
  emergency_contact_name?: string;
  emergency_contact_phone?: string;
  emergency_contact_relationship?: SelectOption;
  end_employer_id?: number;
  end_employer_type?: string;
  expense_proof_file?: string;
  flex_allowance?: boolean;
  flex_percentage?: number;
  full_name: string;
  gross_year_salary?: number;
  iban_file?: string;
  iban_number: string;
  id: number;
  id_number?: string;
  id_number_back_file?: string;
  id_number_front_file?: string;
  job_description?: string;
  magic_token_expire_at: string;
  magic_token_status: SelectOption;
  marital_status?: SelectOption;
  nationality?: CountryOption;
  nr_people_phi_questionarie?: number;
  partner_birthdate?: string;
  partner_child_phi?: boolean;
  partner_dependant_worker: boolean;
  partner_name?: string;
  partner_same_tax_residence?: boolean;
  phi_quesionaire_file?: string;
  phone_nr?: string;
  postal_code?: string;
  region?: string;
  residence_wp_expires_at?: string;
  residence_wp_number?: string;
  residence_wp_number_file?: string;
  schedule_exemption?: boolean;
  special_tax?: SelectOption;
  special_tax_file?: string;
  ss_added?: boolean;
  ss_admition_certificate_file?: string;
  ss_number: string;
  ss_number_file?: string;
  starting_date: string;
  starting_date_tentative?: boolean;
  status: SelectOption;
  street_address?: string;
  tax_id_number?: string;
  tax_id_number_file?: string;
  tax_residency: SelectOption;
  title: string;
  trial_period?: boolean;
  trial_period_duration?: string;
  twelfhts?: string;
  updated_at?: string;
  user?: User;
  wants_phi?: boolean;
  welcome_email_sent?: boolean;
  work_city?: string;
  work_country?: string;
  work_from_home?: boolean;
  work_medicine_aptm?: boolean;
  work_medicine_credential_file?: string;
  work_medicine_date?: string;
  work_medicine_done?: boolean;
  work_medicine_report_file?: string;
  work_postal_code?: string;
  work_region?: string;
  work_street_address?: string;
  workplace?: string;
}

export type GetOnboardingResponse = ApiResponse<OnboardingObj>;

interface GetOnboardingValidateParams {
  token: string;
}

interface OnboardingValidateObj {
  magic_token_status: string;
}

export type GetOnboardingValidateResponse = ApiResponse<OnboardingValidateObj>;

export function getOnboardingValidate(params: GetOnboardingValidateParams) {
  const { token } = params;
  return httpClient.get(`onboardings/${token}/validate`);
}

interface GetOnboardingParams {
  token: string;
}

export function getOnboarding(params: GetOnboardingParams) {
  const { token } = params;
  return httpClient.get(`onboardings/${token}`);
}

type GetOnboardingOptionsParams = GetOnboardingParams;

export function getOnboardingOptions(params: GetOnboardingOptionsParams) {
  const { token } = params;
  return queryOptions({
    queryKey: ["onboardings", token],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const response = await getOnboarding({ token });
        const data = await response.json<OnboardingObj>();

        return data;
      } catch (error) {
        handleApiResponseError(error);
      }
    },
  });
}