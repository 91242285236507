import { refreshTokens } from "@/services/auth/mutations";
import authStore from "@/stores/authStore";
import { encryptJWT } from "@/utils/user";
import { Logtail } from "@logtail/browser";
import ky from "ky";

const env = import.meta.env;

const logtail = env.PROD && new Logtail(env.VITE_BETTERSTACK_SOURCE_TOKEN);

const httpClient = ky.extend({
  prefixUrl: `${env.VITE_API_URL}/${env.VITE_API_VERSION}`,
  credentials: "include",
  cache: "no-store",
  hooks: {
    afterResponse: [
      async (request, _options, response) => {
        if (request.url.includes("options/")) {
          return;
        }

        if (logtail) {
          const requestUrl = new URL(request.url);
          const responseUrl = new URL(response.url);
          const LOG_MESSAGE = `${request.method} ${requestUrl.pathname} ${responseUrl.pathname} ${response.status}`;
          const dataToSend = {
            env: env.VITE_ENVIRONMENT,
            status: response.status,
            url: response.url,
            headers: response.headers,
            body: await response.json(),
          };
          if (response.ok) {
            logtail.info(LOG_MESSAGE, dataToSend);
          } else {
            logtail.error(LOG_MESSAGE, dataToSend);
          }
          logtail.flush();
        }
      },
      async (request, _options, response) => {
        if (response.status === 401) {
          const refreshTokenResponse = await refreshTokens();
          if (refreshTokenResponse) {
            const encryptedUser = encryptJWT(refreshTokenResponse as unknown as Record<string, unknown>);
            authStore.setState({ user: encryptedUser });
            return ky(request);
          }

          authStore.getState().clearUser();
          window.location.replace("/login");
        }

        return response;
      },
    ],
  },
});

export default httpClient;
