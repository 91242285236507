import queryClient from "@/config/queryClient";
import CompaniesNewPage from "@/pages/Group/GroupIdentifier/Companies/New";
import { getBridgeInCountriesOptions } from "@/services/options/optionsQueries";
import { createFileRoute } from "@tanstack/react-router";
import { memo } from "react";

export const Route = createFileRoute("/_authenticated/group/$groupIdentifier/companies/new")({
  component: memo(CompaniesNewPage),
  loader: () => {
    return queryClient.ensureQueryData(getBridgeInCountriesOptions);
  },
});
