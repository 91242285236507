import type { UploadZoneFormConfig } from "@/common";
import { t } from "i18next";

export const ADMIN_ROLES = ["platform_admin", "platform_agent", "platform_support"];
export const GROUP_ADMIN_ROLES = ["group_admin"];

export const FILE_DATE_FORMAT = "YYYY-MM-DDTHH-MM-ss";

export const FORM_DATE_FORMAT = "DD/MM/YYYY";
export const TABLE_DATE_FORMAT = "DD/MM/YYYY";

export const DEFAULT_UPLOAD_ZONE_ERROR_MESSAGE: UploadZoneFormConfig["uploadZoneErrorMessage"] = {
  fileInvalidType: `${t("form.field.uploadZone.error.fileInvalidType")} `,
  fileTooLarge: `${t("form.field.uploadZone.error.fileTooLarge")} `,
};
