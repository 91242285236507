import { getUser } from "@/utils/user";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/login")({
  beforeLoad: () => {
    const user = getUser();
    if (user) {
      throw redirect({ to: "/" });
    }
  },
});
