import { checkIsAdmin } from "@/utils/auth";
import { createFileRoute, notFound } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/admin")({
  beforeLoad: async () => {
    const isUserAdmin = await checkIsAdmin();
    if (!isUserAdmin) {
      throw notFound();
    }
  },
});
