import httpClient from "@/config/httpClient";
import ky from "ky";

const env = import.meta.env;

export interface UserSignInBody {
  email: string;
  password: string;
}

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  first_and_last_name: string;
  avatar_url: string;
  roles: string[];
}

interface AbilityMap {
  [key: string]: "read" | "write" | "manage" | "download_file";
}

interface UserGroup {
  id: number;
  name: string;
  identifier: string;
  logo_path: string;
  total_companies: number;
  enabled: boolean;
}

export interface UserSignInResponse {
  expires_in: number;
  expires_at: string;
  roles: string[];
  ability_map: AbilityMap;
  user: User;
  source_user?: User;
  group?: UserGroup;
}

export type UserObj = UserSignInResponse;

export async function userSignIn(body: UserSignInBody) {
  return httpClient.post("users/tokens/sign_in", {
    json: body,
  });
}

export async function userSignOut() {
  return httpClient.post("users/tokens/revoke");
}

export async function refreshTokens() {
  let result = null;

  try {
    const response = await ky.post(`${env.VITE_API_URL}/${env.VITE_API_VERSION}/users/tokens/refresh`, {
      credentials: "include",
    });
    if (response.ok) {
      result = await response.json<UserObj>();
    }
  } catch (_error) {
    result = null;
  }

  return result;
}
