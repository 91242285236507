import { checkIfAuthenticated, checkIsAdmin, checkIsGroupAdmin } from "@/utils/auth";
import { createFileRoute, notFound, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  beforeLoad: async () => {
    const user = await checkIfAuthenticated();
    const isAdmin = await checkIsAdmin();
    if (isAdmin) {
      throw redirect({ to: "/admin/companies", search: { page: 1 } });
    }

    const isGroupAdmin = await checkIsGroupAdmin();
    if (isGroupAdmin) {
      throw redirect({
        to: "/group/$groupIdentifier",
        params: {
          groupIdentifier: user.group?.identifier || "",
        },
      });
    }

    throw notFound();
  },
});
