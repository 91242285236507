import { redirectNewIncorporationLoader } from "@/utils/incorporation";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/group/$groupIdentifier/incorporation/$companyIdentifier/step/2")({
  loader: ({ params }) => {
    const { companyIdentifier } = params;

    if (companyIdentifier === "create-new") {
      redirectNewIncorporationLoader(params);
    }
  },
});
