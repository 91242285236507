import { createFileRoute, redirect } from "@tanstack/react-router";
import * as v from "valibot";

const newPasswordComponentSchema = v.object({
  reset_password_token: v.string(),
});

type NewPasswordComponentSchema = v.InferOutput<typeof newPasswordComponentSchema>;

export const Route = createFileRoute("/login/reset-password")({
  beforeLoad: async ({ search }) => {
    const searchParams = search;
    if (!searchParams.reset_password_token) {
      throw redirect({ to: "/login" });
    }
  },
  validateSearch: (search: NewPasswordComponentSchema) => v.parse(newPasswordComponentSchema, search),
});
