import { type GetOnboardingValidateResponse, getOnboardingValidate } from "@/services/onboarding/onboardingQueries";
import store from "@/stores/store";
import { createFileRoute, notFound } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/$token")({
  loader: async ({ params }) => {
    const { token } = params;
    try {
      const onboardingResponse = await getOnboardingValidate({ token });
      const response = await onboardingResponse.json<GetOnboardingValidateResponse>();

      store.getState().setOnboardingStatus(response.record?.magic_token_status);
    } catch (_) {
      throw notFound();
    }
  },
});
