import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { queryOptions, useQuery } from "@tanstack/react-query";

export type GroupsOption = SelectOption;
export type GetOptionsGroupsResponse = GroupsOption[];

export async function getGroups() {
  return await httpClient.get("options/groups");
}

export const getGroupsOptions = queryOptions({
  queryKey: ["options", "groups"],
  queryFn: async () => {
    const response = await getGroups();
    return response.json<GetOptionsGroupsResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
});

export interface CountryOption extends SelectOption {
  status?: string;
}
export type GetOptionsCountriesResponse = CountryOption[];
interface GetOptionsCountriesParams {
  nationality: boolean;
}

export async function getCountries(params: GetOptionsCountriesParams) {
  const { nationality = false } = params;
  return await httpClient.get("options/countries", {
    searchParams: { nationality },
  });
}

export const getCountriesOptions = queryOptions({
  queryKey: ["options", "countries"],
  queryFn: async () => {
    const response = await getCountries({ nationality: false });
    return await response.json<GetOptionsCountriesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});
export const getNationalityOptions = queryOptions({
  queryKey: ["options", "nationality"],
  queryFn: async () => {
    const response = await getCountries({ nationality: true });
    return await response.json<GetOptionsCountriesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

export type BridgeInCountryOption = SelectOption;
export type GetOptionsBridgeInCountriesResponse = BridgeInCountryOption[];

export async function getBridgeInCountries() {
  return await httpClient.get("options/bi_countries");
}

export const getBridgeInCountriesOptions = queryOptions({
  queryKey: ["options", "bi_countries"],
  queryFn: async () => {
    const response = await getBridgeInCountries();
    return response.json<GetOptionsBridgeInCountriesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type MaritalRegimeOption = SelectOption;
export type GetMaritalRegimesResponse = MaritalRegimeOption[];
async function getMaritalRegimes() {
  return await httpClient.get("options/marital_regimes");
}

export const getMaritalRegimesOptions = queryOptions({
  queryKey: ["options", "marital_regimes"],
  queryFn: async () => {
    const repsponse = await getMaritalRegimes();
    return repsponse.json<GetMaritalRegimesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type MaritalStatusOption = SelectOption;
export type GetMaritalStatusResponse = MaritalStatusOption[];
async function getMaritalStatuses() {
  return await httpClient.get("options/marital_statuses");
}

export const getMaritalStatusesOptions = queryOptions({
  queryKey: ["options", "marital_statuses"],
  queryFn: async () => {
    const response = await getMaritalStatuses();
    return response.json<GetMaritalStatusResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type ShareholderTypeOption = SelectOption;
export type GetShareholderTypesResponse = ShareholderTypeOption[];
async function getShareholderTypes() {
  return await httpClient.get("options/shareholder_types");
}

export const getShareholderTypesOptions = queryOptions({
  queryKey: ["options", "shareholder_types"],
  queryFn: async () => {
    const response = await getShareholderTypes();
    return response.json<GetShareholderTypesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type CompanyOption = SelectOption;
export type GetCompaniesResponse = CompanyOption[];
interface GetCompaniesParams {
  group_id: number;
}

export async function getCompanies(params?: GetCompaniesParams) {
  return await httpClient.get("options/companies", {
    searchParams: { ...params },
  });
}

export const useGetCompanies = (params: GetCompaniesParams) => {
  const { group_id } = params;

  return useQuery({
    queryKey: ["options", "companies", "group_id", group_id],
    queryFn: async () => {
      const response = await getCompanies({ group_id });
      return response.json<GetCompaniesResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled: false,
  });
};

type EducationOption = SelectOption;
export type GetEducationResponse = EducationOption[];
export async function getEducation() {
  return await httpClient.get("options/education");
}

export const getEducationOptions = queryOptions({
  queryKey: ["options", "education"],
  queryFn: async () => {
    const response = await getEducation();
    return response.json<GetEducationResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type EmergencyContactRelationshipOption = SelectOption;
export type GetEmergencyContactRelationshipResponse = EmergencyContactRelationshipOption[];
export async function getEmergencyContactRelationships() {
  return await httpClient.get("options/emergency_contact_relationships");
}

export const getEmergencyContactRelationshipsOptions = queryOptions({
  queryKey: ["options", "emergency_contact_relationships"],
  queryFn: async () => {
    const response = await getEmergencyContactRelationships();
    return response.json<GetEmergencyContactRelationshipResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type TaxResidenciesOption = SelectOption;
export type GetTaxResidenciesResponse = TaxResidenciesOption[];
export async function getTaxResidencies() {
  return await httpClient.get("options/tax_residencies");
}

export const getTaxResidenciesOptions = queryOptions({
  queryKey: ["options", "tax_residencies"],
  queryFn: async () => {
    const response = await getTaxResidencies();
    return response.json<GetTaxResidenciesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

type SpecialTaxesOption = SelectOption;
export type GetSpecialTaxesResponse = SpecialTaxesOption[];
export async function getSpecialTaxes() {
  return await httpClient.get("options/special_taxes");
}

export const getSpecialTaxesOptions = queryOptions({
  queryKey: ["options", "special_taxes"],
  queryFn: async () => {
    const response = await getSpecialTaxes();
    return response.json<GetSpecialTaxesResponse>();
  },
  throwOnError: (error) => {
    handleApiResponseError(error);
    return false;
  },
  staleTime: Number.POSITIVE_INFINITY,
});
