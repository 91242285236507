import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface AuthState {
  user?: string;
}

interface AuthAction {
  setUser: (user?: string) => void;
  clearUser: () => void;
}

const initialStoreState: AuthState = {
  user: undefined,
};

type AuthStore = AuthState & AuthAction;

const useStore = create<AuthStore>()(
  persist(
    immer((set) => ({
      ...initialStoreState,
      setUser: (user) => {
        set((state) => {
          state.user = user;
        });
      },
      clearUser: () => {
        set((state) => {
          state.user = undefined;
        });
      },
    })),
    {
      name: "authStore",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useStore;
