import { type IncorporationNewCompanyForm, incorporationNewCompanyFormSchema } from "@/schemas/incorporationSchema";
import { useCreateGroupCompany } from "@/services/companies/companiesMutations";
import { getBridgeInCountriesOptions } from "@/services/options/optionsQueries";
import { handleApiResponseError } from "@/utils/errors";
import { toastSuccess } from "@/utils/toasts";
import { Button, Label, Modal, Select } from "@bridgein/react-components";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, getRouteApi } from "@tanstack/react-router";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const route = getRouteApi("/_authenticated/group/$groupIdentifier/companies/new");

export default function CompaniesNewPage() {
  const { t } = useTranslation();
  const { groupIdentifier } = route.useParams();
  const navigate = route.useNavigate();

  const bridgeInCountriesQuery = useSuspenseQuery(getBridgeInCountriesOptions);
  const bridgeInCountriesOptions = bridgeInCountriesQuery.data || [];

  const [openModal, setOpenModal] = useState(false);
  const form = useForm<IncorporationNewCompanyForm>({
    resolver: valibotResolver(incorporationNewCompanyFormSchema),
    defaultValues: {
      country_id: {
        value: bridgeInCountriesOptions ? Number(bridgeInCountriesOptions[0].value) : 0,
        label: bridgeInCountriesOptions[0]?.label || "",
      },
    },
  });
  const { handleSubmit, formState, control } = form;

  const createCompany = useCreateGroupCompany(groupIdentifier);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    if (formState.isSubmitting) return;
    setOpenModal(false);
  };

  const handleCreateVirtualCompany = async (data: IncorporationNewCompanyForm) => {
    try {
      const response = await createCompany.mutateAsync({
        eor: true,
        country_id: data.country_id.value,
      });
      toastSuccess(response.message);
      navigate({
        to: "/group/$groupIdentifier/companies",
        params: {
          groupIdentifier,
        },
      });
    } catch (error) {
      handleApiResponseError(error, form);
    }
  };

  return (
    <>
      <form className="mt-12 flex w-full flex-col justify-center gap-4">
        <div className="flex justify-center gap-4">
          <Button
            className="min-w-[420px] flex-1 font-semibold capitalize"
            onClick={handleOpenModal}
            loading={formState.isSubmitting}
            type="button"
          >
            {t("companies.new.button.virtual-company")}
          </Button>
          <Button className="min-w-[420px] flex-1 font-semibold capitalize" asChild>
            <Link
              to="/group/$groupIdentifier/incorporation/$companyIdentifier/step/1"
              params={{ groupIdentifier, companyIdentifier: "create-new" }}
            >
              {t("companies.new.button.real-company")}
            </Link>
          </Button>
        </div>
        <Modal open={openModal} onOpenChange={handleCloseModal}>
          <Modal.Content
            dialogOverlayProps={{
              className: "backdrop-blur-lg bg-white/10 group/body:overflow-hidden",
            }}
            onInteractOutside={handleCloseModal}
            className="max-h-[360px] w-[500px]"
          >
            <Modal.Title>{t("companies.new.modal.title.virtualCompany")}</Modal.Title>
            {t("companies.new.modal.text.virtualCompany")}
            <Label label={t("form.field.country")} isRequired>
              <Controller
                control={control}
                name="country_id"
                render={({ field }) => (
                  <Select
                    placeholder=""
                    controlFocusClassName="!ring-1"
                    {...field}
                    isDisabled={formState.isSubmitting}
                    options={bridgeInCountriesOptions}
                  />
                )}
              />
            </Label>
            <Modal.Footer className="mt-8">
              <Button
                variant="outline"
                className="flex-1 font-semibold"
                onClick={handleCloseModal}
                disabled={formState.isSubmitting}
              >
                {t("button.cancel.text")}
              </Button>
              <Button
                className="flex-1 font-semibold"
                onClick={handleSubmit(handleCreateVirtualCompany)}
                loading={formState.isSubmitting}
              >
                {t("button.create.text")}
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </form>
    </>
  );
}
