import queryClient from "@/config/queryClient";
import { getCountriesOptions, getGroupsOptions } from "@/services/options/optionsQueries";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/admin/customers/new")({
  loader: () => {
    const countriesPromise = queryClient.ensureQueryData(getCountriesOptions);
    const groupsPromise = queryClient.ensureQueryData(getGroupsOptions);
    return Promise.all([countriesPromise, groupsPromise]);
  },
});
