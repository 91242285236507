import queryClient from "@/config/queryClient";
import { getIncorporationManagerMemberOptions } from "@/services/incorporation/managers/incorporationManagersQueries";
import {
  getCountriesOptions,
  getMaritalRegimesOptions,
  getMaritalStatusesOptions,
  getShareholderTypesOptions,
} from "@/services/options/optionsQueries";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/group/$groupIdentifier/incorporation/$companyIdentifier/managers/$managerId",
)({
  loader: ({ params }) => {
    const { groupIdentifier, companyIdentifier, managerId } = params;

    const shareholderTypesPromise = queryClient.ensureQueryData(getShareholderTypesOptions);
    const maritalRegimePromise = queryClient.ensureQueryData(getMaritalRegimesOptions);
    const maritalStatusPromise = queryClient.ensureQueryData(getMaritalStatusesOptions);
    const countriesPromise = queryClient.ensureQueryData(getCountriesOptions);

    const getManagerPromise = queryClient.ensureQueryData(
      getIncorporationManagerMemberOptions({ groupIdentifier, companyIdentifier, managerId }),
    );

    return Promise.all([
      shareholderTypesPromise,
      maritalRegimePromise,
      maritalStatusPromise,
      countriesPromise,
      getManagerPromise,
    ]);
  },
});
