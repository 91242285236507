import httpClient from "@/config/httpClient";
import type { PaginationParamsSchema } from "@/services/servicesSchemas";
import { handleApiResponseError } from "@/utils/errors";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type * as v from "valibot";

export interface Company {
  id: number;
  enabled: boolean;
  name: string;
  identifier: string;
  logo_path: string;
  total_companies: number;
}

export type GetGroupAdminResponse = Company;

export function getGroupAdmin(groupIdentifier: string) {
  return httpClient.get(`admin/groups/${groupIdentifier}`);
}

type Companies = Company[];
export interface GetGroupsAdminResponse extends Pagination {
  items: Companies;
}

type GetGroupsAdminParams = v.InferOutput<typeof PaginationParamsSchema>;

function getGroupsAdmin(params: GetGroupsAdminParams) {
  const { page, sort } = params;
  const searchParams: GetGroupsAdminParams = {
    page,
  };
  if (sort) searchParams.sort = sort;
  return httpClient.get("admin/groups", { searchParams });
}

export function useGetGroupsAdmin(params: GetGroupsAdminParams) {
  const { page, sort } = params;
  return useQuery({
    queryKey: ["groups", page, sort],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await getGroupsAdmin(params);
      return await response.json<GetGroupsAdminResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
  });
}
