import NotFoundSvg from "@/assets/images/NotFound.svg?react";
import { Button } from "@bridgein/react-components";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="grid w-full place-items-center">
      <div className="mt-24 flex flex-col items-center justify-center gap-16">
        <div>
          <NotFoundSvg />
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <h3 className="text-6xl font-bold">Oops!</h3>
          <p className="text-2xl text-gray-500/80">{t("notFoundPage.wrongPage.text")}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <h2 className="text-8xl font-bold">404</h2>
          <Button asChild variant="outline" className="text-md w-72 font-semibold">
            <Link to="/">{t("notFoundPage.button.goToHomePage")}</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
