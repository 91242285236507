import queryClient from "@/config/queryClient";
import {
  getCountriesOptions,
  getMaritalRegimesOptions,
  getMaritalStatusesOptions,
  getShareholderTypesOptions,
} from "@/services/options/optionsQueries";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/group/$groupIdentifier/incorporation/$companyIdentifier/shareholders/new",
)({
  loader: () => {
    const shareholderTypesPromise = queryClient.ensureQueryData(getShareholderTypesOptions);
    const maritalRegimePromise = queryClient.ensureQueryData(getMaritalRegimesOptions);
    const maritalStatusPromise = queryClient.ensureQueryData(getMaritalStatusesOptions);
    const countriesPromise = queryClient.ensureQueryData(getCountriesOptions);

    return Promise.all([shareholderTypesPromise, maritalRegimePromise, maritalStatusPromise, countriesPromise]);
  },
});
