import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { queryOptions } from "@tanstack/react-query";

interface GetIncorporationManagerParams {
  groupIdentifier: string;
  companyIdentifier: string;
  managerId: string;
}

export interface ManagerMember {
  id: number;
  full_name: string;
  marital_status: SelectOption;
  marital_regime: SelectOption;
  spouse_full_name: string;
  address: string;
  postal_code: string;
  city: string;
  country: SelectOption;
  region: string;
  also_shareholder: boolean;
  email: string;
  power_attorney_incorporation: string;
  passport: string;
  power_attorney_nif: string;
  proof_address: string;
  completion_percentage: number;
  shareholder_id_ref: number;
}

type GetIncorporationManagerResponse = ManagerMember;

export function getIncorporationManagerMember(params: GetIncorporationManagerParams) {
  const { groupIdentifier, companyIdentifier, managerId } = params;

  return httpClient.get(`group/${groupIdentifier}/companies/${companyIdentifier}/managers/${managerId}`);
}

export const getIncorporationManagerMemberOptions = (params: GetIncorporationManagerParams) => {
  const { companyIdentifier, groupIdentifier, managerId } = params;
  return queryOptions({
    queryKey: ["group", groupIdentifier, "companies", companyIdentifier, "managers", managerId],
    queryFn: async () => {
      const response = await getIncorporationManagerMember({ groupIdentifier, companyIdentifier, managerId });
      return await response.json<GetIncorporationManagerResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
  });
};
