import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { queryOptions } from "@tanstack/react-query";

interface GetIncorporationShareholderParams {
  groupIdentifier: string;
  companyIdentifier: string;
  shareholderId: string;
}

export interface ShareholderMember {
  id: number;
  articles_of_incorporation?: string;
  ceo_address: string;
  ceo_city: string;
  ceo_country: SelectOption;
  ceo_full_name: string;
  ceo_marital_regime?: SelectOption;
  ceo_marital_status: SelectOption;
  ceo_passport?: string;
  ceo_postal_code: string;
  ceo_region: string;
  ceo_spouse_full_name: string;
  certifcate_good_standing?: string;
  certificate_of_incorporation?: string;
  company_address?: string;
  company_city?: string;
  company_country?: SelectOption;
  company_name?: string;
  company_postal_code?: string;
  company_region?: string;
  power_attorney_incorporation?: string;
  power_attorney_nif?: string;
  proof_address?: string;
  shareholder_type: SelectOption;
  completion_percentage?: number;
  ceo_email: string;
  also_manager?: boolean;
}

type GetIncorporationShareholderResponse = ShareholderMember;

export function getIncorporationShareholderMember(params: GetIncorporationShareholderParams) {
  const { groupIdentifier, companyIdentifier, shareholderId } = params;

  return httpClient.get(`group/${groupIdentifier}/companies/${companyIdentifier}/shareholders/${shareholderId}`);
}

export const getIncorporationShareholderMemberOptions = (params: GetIncorporationShareholderParams) => {
  const { companyIdentifier, groupIdentifier, shareholderId } = params;
  return queryOptions({
    queryKey: ["group", groupIdentifier, "companies", companyIdentifier, "shareholders", shareholderId],
    queryFn: async () => {
      const response = await getIncorporationShareholderMember({ groupIdentifier, companyIdentifier, shareholderId });
      return await response.json<GetIncorporationShareholderResponse>();
    },
    throwOnError: (error) => {
      handleApiResponseError(error);
      return false;
    },
  });
};
