import authStore from "@/stores/authStore";
import { redirect } from "@tanstack/react-router";
import dayjs from "dayjs";
import { ADMIN_ROLES, GROUP_ADMIN_ROLES } from "./consts";
import { getUser } from "./user";

export async function checkIfAuthenticated() {
  const clearUser = authStore.getState().clearUser;
  const user = getUser();
  if (!user) {
    throw redirect({ to: "/login" });
  }

  if (dayjs().isAfter(dayjs(user.expires_at))) {
    clearUser();
    throw redirect({ to: "/login" });
  }

  return user;
}

export async function checkIsAdmin() {
  const user = getUser();
  if (!user) return false;
  return user.roles.some((role) => ADMIN_ROLES.includes(role));
}

export async function checkIsGroupAdmin() {
  const user = getUser();
  if (!user) return false;
  return user.roles.some((role) => GROUP_ADMIN_ROLES.includes(role));
}
