import App from "@/App";
import ReactDOM from "react-dom/client";
import "@bridgein/react-components/dist/style.css";
import "react-toastify/dist/ReactToastify.min.css";
import "@/styles/index.css";
import "@/config/i18n/i18n";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import dayjsPT from "dayjs/locale/pt";
import dayJsCustomParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale(dayjsPT);
dayjs.extend(dayJsCustomParseFormat);

const env = import.meta.env;

Sentry.init({
  enabled: env.PROD,
  dsn: env.VITE_SENTRY_DSN,
  environment: env.VITE_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.httpClientIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
