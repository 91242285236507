import httpClient from "@/config/httpClient";
import { handleApiResponseError } from "@/utils/errors";
import { toastSuccess } from "@/utils/toasts";
import { useMutation } from "@tanstack/react-query";
import type { GetGroupCompanyResponse } from "./companiesQueries";

interface CreateGroupCompanyBodyParams {
  eor: boolean;
  country_id: number;
  trial_name_1?: string;
  trial_name_2?: string;
  trial_name_3?: string;
  social_capital?: number;
  social_object?: string;
  save_and_close?: boolean;
}

export interface CreateGroupCompanyParams {
  groupIdentifier: string;
  body: CreateGroupCompanyBodyParams;
}

export type CreateGroupCompanyResponse = GetGroupCompanyResponse;

export function createGroupCompany(params: CreateGroupCompanyParams) {
  const { groupIdentifier, body } = params;

  return httpClient.post(`group/${groupIdentifier}/companies`, {
    json: body,
  });
}

export function useCreateGroupCompany(groupIdentifier: CreateGroupCompanyParams["groupIdentifier"]) {
  return useMutation<ApiResponse<CreateGroupCompanyResponse>, Error, CreateGroupCompanyParams["body"]>({
    mutationFn: async (body) => {
      const response = await createGroupCompany({ groupIdentifier, body });
      return await response.json();
    },
  });
}

type UpdateGroupCompanyBodyParams = CreateGroupCompanyBodyParams;

export interface UpdateGroupCompanyParams {
  groupIdentifier: string;
  companyIdentifier: string;
  body: Partial<UpdateGroupCompanyBodyParams>;
}

export type UpdateGroupCompanyResponse = GetGroupCompanyResponse;

export function updateGroupCompanyStep1(params: UpdateGroupCompanyParams) {
  const { groupIdentifier, companyIdentifier, body } = params;

  return httpClient.patch(`group/${groupIdentifier}/companies/${companyIdentifier}/step1`, {
    json: body,
  });
}

export function useUpdateGroupCompanyStep1(
  groupIdentifier: UpdateGroupCompanyParams["groupIdentifier"],
  companyIdentifier: UpdateGroupCompanyParams["companyIdentifier"],
) {
  return useMutation<ApiResponse<UpdateGroupCompanyResponse>, Error, UpdateGroupCompanyParams["body"]>({
    mutationFn: async (body) => {
      const response = await updateGroupCompanyStep1({ groupIdentifier, companyIdentifier, body });
      return await response.json();
    },
  });
}

export interface UpdateGroupCompanyStep2Params {
  groupIdentifier: string;
  companyIdentifier: string;
  save_and_close: boolean;
}

export function updateGroupCompanyStep2(params: UpdateGroupCompanyStep2Params) {
  const { companyIdentifier, groupIdentifier, save_and_close } = params;

  return httpClient.patch(`group/${groupIdentifier}/companies/${companyIdentifier}/step2`, {
    json: {
      save_and_close,
    },
  });
}

interface UpdateGroupCompanyStep2Variables {
  save_and_close: boolean;
}

export function useUpdateGroupCompanyStep2(params: Omit<UpdateGroupCompanyStep2Params, "save_and_close">) {
  const { groupIdentifier, companyIdentifier } = params;

  return useMutation<ApiResponseMessage, Error, UpdateGroupCompanyStep2Variables>({
    mutationFn: async (vars) => {
      const { save_and_close } = vars;
      const response = await updateGroupCompanyStep2({ groupIdentifier, companyIdentifier, save_and_close });
      return await response.json<ApiResponseMessage>();
    },
    onSuccess: (data) => {
      toastSuccess(data.message);
    },
    onError: (error) => {
      handleApiResponseError(error);
    },
  });
}
