import AuthRouterProvider from "@/providers/AuthRouterProvider";
import ReactQueryProvider from "@/providers/ReactQueryProvider";
import { MantineProvider } from "@mantine/core";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <MantineProvider
      theme={{
        fontFamily: "Montserrat, sans-serif",
        fontFamilyMonospace: "Montserrat, sans-serif",
        primaryColor: "bridgein",
        primaryShade: 8,
        colors: {
          bridgein: [
            "#ffe9e9",
            "#ffd0d0",
            "#fd9d9d",
            "#fc6766",
            "#fc3c39",
            "#fc231c",
            "#fd160e",
            "#e20a03",
            "#cc0000",
            "#b00000",
          ],
        },
      }}
    >
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        autoClose={3000}
        stacked={true}
        bodyClassName="text-xs font-bold text-black"
      />
      <ReactQueryProvider>
        <AuthRouterProvider />
      </ReactQueryProvider>
    </MantineProvider>
  );
}
