import { redirect } from "@tanstack/react-router";

interface RedirectNewIncorporationLoaderArgs {
  groupIdentifier: string;
  companyIdentifier: string;
}

export function redirectNewIncorporationLoader(args: RedirectNewIncorporationLoaderArgs) {
  throw redirect({
    to: "/group/$groupIdentifier/incorporation/$companyIdentifier/step/1",
    params: args,
  });
}
