import NotFoundPage from "@/components/NotFoundPage";
import { ErrorComponent, createRouter } from "@tanstack/react-router";
import { routeTree } from "../routeTree.gen";

// Set up a Router instance
const router = createRouter({
  routeTree,
  defaultPreload: false,
  defaultNotFoundComponent: () => <NotFoundPage />,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  notFoundMode: "root",
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default router;
