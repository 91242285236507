import type { GetGroupCompanyResponse } from "@/services/companies/companiesQueries";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface StoreState {
  showRequestPasswordSuccessAlert?: boolean;
  incorporationData?: GetGroupCompanyResponse;
  onboardingStatus?: string;
}

interface StoreAction {
  setShowRequestPasswordSuccessAlert: (message: boolean) => void;
  setIncorporationData: (data?: GetGroupCompanyResponse) => void;
  deleteIncorporationManager: (id: number) => void;
  deleteIncorporationShareholder: (id: number) => void;
  clearIncorporationData: () => void;
  setOnboardingStatus: (status?: string) => void;
}

const initialStoreState: StoreState = {
  showRequestPasswordSuccessAlert: false,
  incorporationData: undefined,
};

type MainStore = StoreState & StoreAction;

const useStore = create<MainStore>()(
  devtools(
    immer((set) => ({
      ...initialStoreState,
      setShowRequestPasswordSuccessAlert: (message) => {
        set((state) => {
          state.showRequestPasswordSuccessAlert = message;
        });
      },
      setIncorporationData: (data) => {
        set((state) => {
          state.incorporationData = data;
        });
      },
      deleteIncorporationManager(id) {
        set((state) => {
          if (state.incorporationData?.managers && state.incorporationData.managers.length > 0) {
            state.incorporationData.managers = state.incorporationData.managers.filter(
              (shareholder) => shareholder.id !== id,
            );
          }
        });
      },
      deleteIncorporationShareholder(id) {
        set((state) => {
          if (state.incorporationData?.shareholders && state.incorporationData.shareholders.length > 0) {
            state.incorporationData.shareholders = state.incorporationData.shareholders.filter(
              (shareholder) => shareholder.id !== id,
            );
          }
        });
      },
      clearIncorporationData: () => {
        set((state) => {
          state.incorporationData = undefined;
        });
      },
      setOnboardingStatus: (status) => {
        set((state) => {
          state.onboardingStatus = status;
        });
      },
    })),

    {
      enabled: import.meta.env.DEV,
    },
  ),
);

export default useStore;
